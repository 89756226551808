import store from "@/store";

let worker = [
  {
    title: "Дашборд",
    route: "dashboard",
    icon: "HomeIcon",
  },
  {
    header: "Робота",
  },
  {
    title: "Всі звернення",
    route: "tickets",
    icon: "FileIcon",
  },
  {
    title: "Мій профіль",
    route: "profile",
    icon: "UserIcon",
  },
  {
    title: "Записи на прийом",
    route: "appointments",
    icon: "CalendarIcon",
  },
];

const admin = [
  {
    title: "Дашборд",
    route: "dashboard",
    icon: "HomeIcon",
  },
  {
    header: "Робота",
  },
  {
    title: "Всі звернення",
    route: "tickets",
    icon: "FileIcon",
  },

  {
    title: "Мій профіль",
    route: "profile",
    icon: "UserIcon",
  },
  {
    header: "Адміністрування",
  },
  {
    title: "Модерація відповідей",
    route: "moderation",
    icon: "CheckSquareIcon",
  },
  {
    title: "Теми звернень",
    route: "topics",
    icon: "AlignLeftIcon",
  },
  {
    title: "Послуги",
    route: "services",
    icon: "SettingsIcon",
  },
  {
    title: "Всі користувачі",
    route: "users",
    icon: "UsersIcon",
  },
  {
    title: "Працівники",
    route: "employees",
    icon: "UsersIcon",
  },
  {
    title: "Відділи, посади",
    route: "departments",
    icon: "FolderIcon",
  },
  {
    title: "Статуси заявок",
    route: "statuses",
    icon: "FlagIcon",
  },
];

let selectedMenu = worker;

if (store.state.role == "admin") {
  selectedMenu = admin;
}

export default selectedMenu;
